/* istanbul ignore file */
export function memoize (fn) {
    const NO_RESULT = Symbol('NO_RESULT')

    let res = NO_RESULT

    return function (...args) {
        if (res === NO_RESULT) {
            return (res = fn.apply(this, args))
        }

        return res
    }
}

export function makeCancelable (promise, mapResult = (result, canceled) => ({...result, canceled})) {
    let isCanceled = false

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then((result) => resolve(mapResult(result, isCanceled)))
    })

    return {
        promise: wrappedPromise,
        cancel () {
            isCanceled = true
        },
    }
}

export function takeLatest (fn) {
    let cancelable

    return function (...args) {
        if (cancelable) {
            cancelable.cancel()
        }

        cancelable = makeCancelable(fn(...args))

        return cancelable.promise
    }
}

export function defer () {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, 0)
    })
}
