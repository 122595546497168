import React from 'react'
import styled from 'styled-components'

const Loader = styled.div`
    position: inherit;
    background-color: #fff;
    line-height: 48px;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    padding-left: 1rem;
    padding-right: 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-style: italic;
    font-weight: 300;
    color: #888888;
`

const PlacesSelectLoader = () => (
    <Loader>Cargando...</Loader>
)

export default PlacesSelectLoader
