import React from 'react'
import PropTypes from 'prop-types'
import { Async } from 'commons/js/components/Select'

import { error } from 'commons/js/util/logger'
import { Button, Icon } from '@elparking/components'

const MIN_LENGTH_FOR_SEARCH = 3

const optionsForSelect = {
    // Disable multi selection
    multi: false,
    // Disable cache
    cache: false,
    searchPromptText: '',
    loadingPlaceholder: '',
    autosize: false,
    onBlurResetsInput: false,
    onCloseResetsInput: false,
    onSelectResetsInput: false,
    filterOptions: false,
}

class PlacesSelect extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        value: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
        }),
        autofocus: PropTypes.bool,
        placeholder: PropTypes.string,
        autocompleteService: PropTypes.func,
        optionRenderer: PropTypes.func,
        onChange: PropTypes.func,
        dataTest: PropTypes.string,
    }

    static defaultProps = {
        autofocus: false,
        placeholder: 'Selecciona un lugar...',
        value: null,
        autocompleteService: () => Promise.resolve([]),
        onChange: () => {},
    }

    state = {
        value: this.props.value,
        placeholder: this.props.placeholder,
        showPlaceholder: true,
        results: [],
        input: null,
    }

    static getDerivedStateFromProps (nextProps, state) {
        if (!nextProps.value) {
            return null
        }

        const valueHasChanged = !state.value || nextProps.value.value !== state.value.value

        if (valueHasChanged) {
            const value = nextProps.value
            const label = (nextProps.value && nextProps.value.label) || state.label
            return {
                value: { value, label },
                label: label,
            }
        }
        return null
    }

    setValue ({ value, label }, next = () => {}) {
        const nextVal = { value, label }
        this.setState(
            ({label: prevLabel}) => ({
                value: nextVal,
                label: label || prevLabel,
            }),
            () => {
                next(nextVal)
            }
        )
    }

    onChange (option) {
        if (!option || !option.value) {
            return
        }
        this.setState({ input: option.label })
        this.setValue(option, this.props.onChange)
    }

    onChange = this.onChange.bind(this)

    loadOptions (input) {
        this.setState({ input })
        const length = input.length
        if (length === 0) {
            return Promise.resolve(this.state.results)
        }
        if (length < MIN_LENGTH_FOR_SEARCH) {
            return Promise.resolve([])
        }
        return this.props.autocompleteService({input})
            .then((results) => {
                this.setState({ results })
                return Promise.resolve(results)
            })
            .catch((e) => error(e.message))
    }

    loadOptions = this.loadOptions.bind(this)

    onFocus () {
        this.setState(
            () => ({showPlaceholder: false}),
        )
    }

    onFocus = this.onFocus.bind(this)

    onBlur () {
        this.setState({showPlaceholder: true})
    }

    onBlur = this.onBlur.bind(this)

    renderClearButton () {
        return <Button.unstyled type='button' onClick={() => {
            this.setState({ input: '' })
            this.setValue({ value: '', label: '' }, this.props.onChange)
        }} fontColor='inherit'>
            <Icon type='x' fontColor='inherit' />
        </Button.unstyled>
    }

    renderClearButton = this.renderClearButton.bind(this)

    render () {
        return (
            <Async
              dataTest={this.props.dataTest}
              className={this.props.className}
              value={this.state.value}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              loadOptions={(inputValue) => this.loadOptions(inputValue) }
              autoFocus={this.props.autofocus}
              autoBlur
              defaultOptions={this.state.results}
              placeholder={this.props.placeholder}
              clearComponent={this.renderClearButton}
              onChange={this.onChange}
              optionRenderer={this.props.optionRenderer}
              valueRenderer={() => {
                    if (this.state.input === null) {
                        return this.state.value.label
                    }
                    return this.state.input
                }}
              {...optionsForSelect}
            />
        )
    }
}

export default PlacesSelect
