import * as api from 'commons/js/api'
import { memoize } from 'commons/js/util/promiseUtils'

function mapPoi (poi) {
    return {
        place_id: `poi-${poi.id}`,
        poi_id: poi.id,
        name: poi.name,
        latitude: poi.latitude,
        longitude: poi.longitude,
    }
}

const makePoisReducer = (options) => (allPois, poi) => {
    return [
        ...allPois,
        mapPoi(poi, options),
    ]
}

export function getAllPois (params) {
    return api.searchPois(params)
}

const cachedGetAllPois = memoize(getAllPois)

export function searchPois (params, options = {}) {
    return api.searchPois(params)
        .then(({ result: results }) => results.reduce(makePoisReducer(options), []))
}

export function cachedSearchPois (params, options = {}) {
    return cachedGetAllPois(params)
        .then(({ result: results }) => results.reduce(makePoisReducer(options), []))
}

export function reverseSearchPois (place) {
    return cachedSearchPois()
        .then((pois) =>
            pois.find((poi) => poi.place_id === place.place_id)
        )
}
