import { getPredictions as getPlacePredictions } from './placesService'
import { getAirportPredictions, getPoisPredictions } from './predictionsService'

export const AIRPORT_TYPE = 'airport'
export const PLACE_TYPE = 'place'
export const POI_TYPE = 'poi'

const MAX_POIS = 3
const MAX_AIRPORTS = 3
const MAX_PLACES = 4

function mapPoiPrediction (prediction) {
    return {
        label: prediction.name,
        value: prediction.place_id,
    }
}

function mapAirportPrediction (prediction) {
    return {
        label: prediction.name,
        value: prediction.place_id,
    }
}

function mapPlacePrediction (prediction) {
    return {
        label: prediction.description,
        value: prediction.place_id,
    }
}

export const mapAirportPredictionWithType = (prediction) => {
    const option = mapAirportPrediction(prediction)

    return {
        ...option,
        value: `${AIRPORT_TYPE}:${option.value}`,
    }
}

export const mapPoiPredictionWithType = (prediction) => {
    const option = mapPoiPrediction(prediction)

    return {
        ...option,
        value: `${POI_TYPE}:${option.value}`,
    }
}

export const mapPlacePredictionWithType = (prediction) => {
    const option = mapPlacePrediction(prediction)
    return {
        ...option,
        value: `${PLACE_TYPE}:${option.value}`,
    }
}

export const makeAirportsAutocomplete = ({ predictionMapper = mapAirportPrediction, formatMessage } = {}) => async (params) => {
    const extendedParams = { ...params, formatMessage }
    const predictions = await getAirportPredictions(extendedParams)

    return predictions.map(predictionMapper).slice(0, MAX_AIRPORTS)
}

export const makePoisAutocomplete = ({ predictionMapper = mapAirportPrediction, formatMessage } = {}) => async (params) => {
    const extendedParams = { ...params, formatMessage }
    const predictions = await getPoisPredictions(extendedParams)

    return predictions.map(predictionMapper).slice(0, MAX_POIS)
}

export const makePlacesAutocomplete = ({ predictionMapper = mapPlacePrediction } = {}) => async (params) => {
    const predictions = await getPlacePredictions(params)

    return predictions.map(predictionMapper).slice(0, MAX_PLACES)
}

export const poisAutocomplete = makePoisAutocomplete()
export const airportsAutocomplete = makeAirportsAutocomplete()
export const placesAutocomplete = makePlacesAutocomplete()

/**
 * Returns a wrapped autocomplete that always resolves, either with predictions or empty array
 */
const makeAutocompleteResolver = (params) =>
    (autocomplete) => autocomplete(params).then((predictions) => predictions, () => [])

export const makeAggregatedAutocomplete = (options = {}) => async (params) => {
    // NOTE: Order here is important, determines which results appear first
    const autocompleters = [
        makeAirportsAutocomplete({ ...options, predictionMapper: mapAirportPredictionWithType }),
        makePoisAutocomplete({ ...options, predictionMapper: mapPoiPredictionWithType }),
        makePlacesAutocomplete({ ...options, predictionMapper: mapPlacePredictionWithType }),
    ]

    const resolvers = autocompleters.map(makeAutocompleteResolver(params))
    const allPredictions = await Promise.all(resolvers)

    // Flat results (waiting for official browser support...)
    return allPredictions.reduce((allValues, value) => [...allValues, ...value], [])
}

export default placesAutocomplete
