import { cachedSearchAirports } from 'commons/js/services/airportsService'
import { cachedSearchPois } from 'commons/js/services/poisService'
import matchWords from 'commons/js/util/matchWords'

const MAX_SEARCH_RESULTS = 5

export async function getAirportPredictions ({ input, formatMessage }) {
    if (!input) {
        return []
    }

    const allAirports = await cachedSearchAirports({}, { formatMessage })

    return allAirports
        .filter((airport) => matchWords(input, airport.name))
        .slice(0, MAX_SEARCH_RESULTS)
}

export async function getPoisPredictions ({ input, formatMessage }) {
    if (!input) {
        return []
    }

    const allPois = await cachedSearchPois({}, { formatMessage })

    return allPois
        .filter((poi) => matchWords(input, poi.name))
        .slice(0, MAX_SEARCH_RESULTS)
}
