import PlacesSelect from './PlacesSelect'
import PlacesSelectLoader from './Loader'
import StyledPlacesSelect from './StyledPlacesSelect'

export type Label = string
export type Value = string | number
export type Option = {
    label: Label,
    value: Value,
}

export {
    PlacesSelect,
    PlacesSelectLoader,
    StyledPlacesSelect,
}

export default StyledPlacesSelect
