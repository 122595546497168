import { cachedSearchAirports } from 'commons/js/services/airportsService'
import { cachedSearchPois } from 'commons/js/services/poisService'
import { AIRPORT_TYPE, PLACE_TYPE, POI_TYPE } from 'commons/js/services/autocompleteService'

function getGeocoderService () {
    return new window.google.maps.Geocoder()
}

function makeGeocoderRequest ({ place_id: placeId, latitude = null, longitude = null }) {
    if (latitude && longitude) {
        return {
            location: {
                lat: latitude,
                lng: longitude,
            },
        }
    }
    return {
        placeId,
    }
}

function fromGeocoderResult (result) {
    return {
        place_id: result.place_id,
        name: result.formatted_address,
        description: result.formatted_address,
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng(),
    }
}

export function geocodePlace (place) {
    return new Promise((resolve, reject) => {
        const geocoderService = getGeocoderService()
        const request = makeGeocoderRequest(place)

        geocoderService.geocode(request, (results) => {
            if (!results.length) {
                reject(new Error('Localización no encontrada'))
                return
            }

            resolve(fromGeocoderResult(results[0]))
        })
    })
}

export function geocodeAirportParking (id, options) {
    return cachedSearchAirports(undefined, options)
        .then((airports) => {
            const result = airports.find(({ airport_id: airportId }) => id === airportId)

            if (!result) {
                return Promise.reject(new Error('Localización no encontrada'))
            }

            return result
        })
}

export function geocodePoiParking (id, options) {
    return cachedSearchPois(undefined, options)
        .then((pois) => {
            const result = pois.find(({ poi_id: poiId }) => id === poiId)

            if (!result) {
                return Promise.reject(new Error('Localización no encontrada'))
            }

            return result
        })
}

export function reverseGeocodeAirport (place, options) {
    return cachedSearchAirports(undefined, options)
        .then((airports) => {
            const result = airports.find((airport) => airport.place_id === place.place_id)
            if (!result) {
                return Promise.reject(new Error('Localización no encontrada'))
            }

            return result
        })
}

export function reverseGeocodePoi (place, options) {
    return cachedSearchPois(undefined, options)
        .then((pois) => {
            const result = pois.find((poi) => poi.place_id === place.place_id)

            if (!result) {
                return Promise.reject(new Error('Localización no encontrada'))
            }

            return result
        })
}

const extractPlaceId = (value) => value.split(':')[1]

export function reverseSearch (place, options) {
    if (!place) {
        return Promise.resolve({})
    }

    if (place.startsWith(POI_TYPE)) {
        return reverseGeocodePoi({ place_id: extractPlaceId(place) }).then((payload) => ({ type: POI_TYPE, payload }))
    }

    if (place.startsWith(PLACE_TYPE)) {
        return geocodePlace({ place_id: extractPlaceId(place) }).then((payload) => ({ type: PLACE_TYPE, payload }))
    }

    if (place.startsWith(AIRPORT_TYPE)) {
        return reverseGeocodeAirport({ place_id: extractPlaceId(place) }, options).then((payload) => ({ type: AIRPORT_TYPE, payload }))
    }

    return Promise.reject(new Error('Cannot find place'))
}
