import { getConstant } from 'commons/js/constants'

function buildAutocompletionRequest ({ input, country = getConstant('COUNTRY_ISO2') || 'ES' }) {
    return {
        input,
        componentRestrictions: {
            country,
        },
    }
}

export async function getPredictions (params) {
    return new Promise((resolve, reject) => {
        const autocompleteService = new window.google.maps.places.AutocompleteService()
        const request = buildAutocompletionRequest(params)

        if (!params.input) {
            resolve([])
            return
        }

        autocompleteService.getPlacePredictions(request, (results) => {
            if (!results) {
                reject(new Error('Error while retrieving places'))
                return
            }

            resolve(results)
        })
    })
}
