import styled from 'styled-components'

import {
    themeColor,
    themeFontWeight,
    themeFontSize,
    themeBorderRadius,
} from '@elparking/components'
import PlacesSelect from './PlacesSelect'

export default styled(PlacesSelect)`
    border: 2px solid ${themeColor('lightGray40')};
    border-radius: ${themeBorderRadius()};
    width: 100%;
    min-width: 100%;
    height: 100%;
    position: relative;
    .Select__value-container {
        width: 100%;
    }

    .Select__value-container .Select__placeholder,
    .Select__value-container .Select__single-value,
    .Select__input-container {
        width: 100%;
    }

    .Select__value-container .Select__single-value,
    .Select__input-container {
        font-weight: ${themeFontWeight('book')};
    }

    .Select__value-container .Select__single-value {
        display: block;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .Select-value-container-label {
        line-height: 1.2em;
        color: ${themeColor('gray')};
        font-weight: ${themeFontWeight('medium')};
    }

    .Select__control {
        outline: none;
        margin: 0;
        position: inherit;
        border: 0;
        border-radius: ${themeBorderRadius()};
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        box-shadow: none;
        min-width: calc(100% - 1px);
        max-width: fit-content;
    }

    .Select__placeholder {
        line-height: 1em;
        font-weight: ${themeFontWeight('regular')};
        color: ${themeColor('lightGray')};
        font-size: ${themeFontSize('small')};
    }

    .Select__input-container  > input {
        box-sizing: border-box !important;
        height: 100%;
        line-height: 1em;
        padding: 0;
    }

    .Select__menu-list {
        padding: 0;
        min-height: 0;
    }


    .Select__option:last-child::after {
        display: block;
        margin-top: 5px;
        margin-left: -1em;
        margin-right: -1em;
        margin-bottom: -1em;
        background-color: ${themeColor('white')};
        background-image: url(${(props) => props.showGoogleBanner ? '/images/powered_by_google_on_white.webp' : ''});
        background-position: center right 5px;
        background-repeat: no-repeat;
        background-size: 120px 14px;
        height: 30px;
        content: '';
    }

    .Select__option {
        line-height: 1.5em;
        color: ${themeColor('gray')};
        font-size: ${themeFontSize('small')};
        font-weight: ${themeFontWeight('regular')};
    }

    .Select__control--is-focused
    .Select__value-container
    .Select__single-value {
        color: ${themeColor('lightGray')};
    }

    .Select__option--is-focused {
        background-color: ${themeColor('accent40')};
        color: ${themeColor('darkMain')};
    }

    .Select__option--is-selected {
        background-color: ${themeColor('accent80')};
        color: ${themeColor('darkMain')};
    }

    .Select__menu,
    .Select__menu-outer {
        background: ${themeColor('white')};
    }

    .Select__menu {
        margin: 0;
        padding: 0;
        margin-top: -3px;
        max-width: 100%;
        min-height: 3px;
        border-radius: 0;
        border-top: 3px solid ${themeColor('main')};
    }

    .Select__menu-outer{
        overflow: hidden;
    }

    .Select__option:last-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .Select__multi-value-container-wrapper {
        position: relative;
        width: 100%;
    }

    @media screen and (min-width: 48em) {
        border-top-left-radius: ${themeBorderRadius()};
        border-bottom-left-radius: ${themeBorderRadius()};
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0.5px;

        .Select__menu,
        .Select__menu-outer {
            max-height: none;
        }
    }

    .Select__indicators {
        padding: 0 1em;
    }
`
