import { defineMessages } from 'react-intl'

import * as api from 'commons/js/api'
import currentCountry from 'commons/js/country'
import { memoize } from 'commons/js/util/promiseUtils'

const messages = defineMessages({
    airportName: {
        defaultMessage: 'Aeropuerto {name}',
    },
})

function mapAirport (airport, { formatMessage = (_, { name }) => name }) {
    return {
        place_id: `airport-${airport.id}`,
        airport_id: airport.id,
        name: formatMessage(messages.airportName, { name: airport.name }),
        latitude: airport.latitude,
        longitude: airport.longitude,
    }
}

const makeAirportsReducer = (options) => (allAirports, airport) => {
    return [
        ...allAirports,
        mapAirport(airport, options),
    ]
}

export function getAllAirports (params) {
    // TODO: Fix me :pray:
    if (currentCountry === 'usa') {
        return Promise.resolve({ result: [] })
    }

    return api.searchAirports(params)
}

export function getAirport (params) {
    return api.getAirport(params)
}

const cachedGetAllAirports = memoize(getAllAirports)

export function searchAirports (params, options = {}) {
    return api.searchAirports(params)
        .then(({ result: results }) => results.reduce(makeAirportsReducer(options), []))
}

export function cachedSearchAirports (params, options = {}) {
    return cachedGetAllAirports(params)
        .then(({ result: results }) => results.reduce(makeAirportsReducer(options), []))
}

export function reverseSearchAirports (place) {
    return cachedSearchAirports()
        .then((airports) =>
            airports.find((airport) => airport.place_id === place.place_id)
        )
}
